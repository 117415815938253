<template>
  <div class="finish">
    <!-- 已完成 -->
    <div>
      <img src="../../assets/image/baicheng/finish.jpg" alt="" />
      <h1>恭喜你完成积极心理品质测试</h1>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {

    };
  },
  methods: {
  },
  beforeDestroy() {
    
  },
  created() {
  
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .finish {
    font-family: 'SYHeiNormal';
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: calc((100% - 500px) / 2);
    .img {
      width: 400px;
      height: 400px;
    }
    h1 {
      font-weight: 550;
      font-size: 28px;
      margin-bottom: 20px;
    }
    .tip {
      margin-bottom: 20px;
      color: #666666;
      font-size: 16px;
      span {
        color: #f59a23;
        font-weight: 550;
        font-size: 20px;
      }
    }
    .submit {
      font-size: 22px;
      border-radius: 30px;
      padding: 10px 30px;
    }
  }
}
@media screen and (min-width: 1900px) {
  .finish {
    font-family: 'SYHeiNormal';
    margin: 0 auto;
    text-align: center;
    position: relative;
    top: calc((100% - 650px) / 2);
    .img {
      width: 520px;
      height: 520px;
    }
    h1 {
      font-weight: 550;
      font-size: 36px;
      margin-bottom: 26px;
    }
    .tip {
      margin-bottom: 26px;
      color: #666666;
      font-size: 21px;
      span {
        color: #f59a23;
        font-weight: 550;
        font-size: 26px;
      }
    }
    .submit {
      font-size: 28px;
      border-radius: 30px;
      padding: 13px 39px;
    }
  }
}
</style>
